// 公用api
import request from '@/tools/request';

// 获取商品例表
export function getList(data) {
  return request({
    url: `/component-goodsSearch-product.html`,
    method: 'get',
    params: data,
  });
}
// 下拉获取品牌和类型
export function getBrandsAndTypes(data) {
  return request({
    url: `/component-goodsSearch-getBrandsAndTypes.html`,
    method: 'get',
    params: data,
  });
}
// 获取分类
export function Ajaxcategorypath(data) {
  return request({
    url: `/component-category-Ajaxcategorypath.html`,
    method: 'get',
    params: data,
  });
}
